import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { NgModule } from '@angular/core';
import {
	RouterModule,
	Routes,
	Router,
	NavigationEnd,
	ActivatedRoute,
} from '@angular/router';
import { HomeComponent } from '../components/home/home.component';
import { MainframeComponent } from '../components/mainframe/mainframe.component';
import { ExpertiseComponent } from '../components/expertise/expertise.component';
import { FeedbackComponent } from '../components/feedback/feedback.component';
import { FeedbackListComponent } from '../components/feedback-list/feedback-list.component';
import { ContactComponent } from '../components/contact/contact.component';
import { PortfolioComponent } from '../components/portfolio/portfolio.component';
import { LeadershipComponent } from '../components/leadership/leadership.component';
import { CareerComponent } from '../components/career/career.component';
import { ApproachComponent } from '../components/approach/approach.component';
import { PrivacyPolicyComponent } from '../components/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from '../components/terms-conditions/terms-conditions.component';
import { Title, Meta } from '@angular/platform-browser';
import { stringify } from '@angular/core/src/render3/util';
import { DownloadProxybeatComponent } from '../components/download-proxybeat/download-proxybeat.component';

const routes: Routes = [
	{
		path: '',
		component: MainframeComponent,
		children: [
			{
				path: '',
				component: HomeComponent,
				data: {
					title: 'Big Data App | AWS Solutions | Elastic Search | Factweavers',
					metaDescription:
						'Factweavers: Leading Big Data Solutions Company, our major services are Elastic Search, AWS solutions, Angular, Cloud solutions, Docker, ELK Stack, ETL Systems',
				},
			},
			{
				path: 'expertise',
				component: ExpertiseComponent,
				data: {
					title: 'Big Data App Solutions & Sechnologies | AWS Solutions | Angular',
					metaDescription:
						'We offer big data applications solutions . Contact us to get best big data app. AWS solutions offers reliable. Angular- front-end web application framework.',
					metaKeyword:
						'Big Data App solutions ,  AWS solutions ,  Angular  ,big data app solutons uk,aws solutions uk,angular uk|  ',
				},
			},
			{
				path: 'approach',
				component: ApproachComponent,
				data: {
					title: 'Best Cloud Solutions and Services | Cloud Technologies',
					metaDescription:
						'A cloud solution services is any service made available to users on demand via the Internet from a cloud computing providers servers',
				},
			},
			{
				path: 'portfolio',
				component: PortfolioComponent,
				data: {
					title: 'Elastic Search Log Stash Kibana (ELK) Stack Management',
					metaDescription:
						'We provide Elastic Search based arrangement and support .Elastic Search -Log Stash is one of the most used technology. Safely examine and review your data.',
				},
			},
			{
				path: 'leadership',
				component: LeadershipComponent,
				data: {
					title: 'Elastic Search Solution and Support | UI Design and Solutions',
					metaDescription:
						'Elastic Search based arrangement and support .Elastic Search -Log Stash is one of the most used technology. Creative team offers responsive UI designs and solutions.',
				},
			},
			{
				path: 'career',
				component: CareerComponent,
				data: {
					title: 'Natural Language Processing Applications & Technologies | Career',
					metaDescription:
						'Natural Language Processing (NLP) is the capacity of a PC program to comprehend human language as it is talked. NLP-segment of artificial intelligence (AI)',
				},
			},
			{
				path: 'contact',
				component: ContactComponent,
				data: {
					title: 'Docker Management Application and Technology | Contact Us',
					metaDescription:
						'Docker is an open-source project for robotizing the organization of applications as versatile, independent holders that can run for all intents and purposes anyplace.',
				},
			},
			{
				path: 'privacy-policy',
				component: PrivacyPolicyComponent,
				data: {
					title: 'Docker Management Application and Technology | Privacy Policy',
					metaDescription:
						'Docker is an open-source project for robotizing the organization of applications as versatile, independent holders that can run for all intents and purposes anyplace.',
				},
			},
			{
				path: 'terms-conditions',
				component: TermsConditionsComponent,
				data: {
					title: 'Docker Management Application and Technology | Terms Conditions',
					metaDescription:
						'Docker is an open-source project for robotizing the organization of applications as versatile, independent holders that can run for all intents and purposes anyplace.',
				},
			},
			// {
			//   path: "proxybeat", component: DownloadProxybeatComponent,
			// },
			{ path: 'client-review', component: FeedbackComponent },
			{ path: 'show-feedback-list', component: FeedbackListComponent },

			{ path: '**', redirectTo: '' },
		],
	},
	// { path: "feedback", component: FeedbackComponent },
	// { path: "show-feedback-list", component: FeedbackListComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
		private metaService: Meta
	) {
		//Boilerplate code to filter out only important router events and to pull out data object field from each route
		this.router.events
			.filter((event) => event instanceof NavigationEnd)
			.map(() => this.activatedRoute)
			.map((route) => {
				while (route.firstChild) route = route.firstChild;
				return route;
			})
			.filter((route) => route.outlet === 'primary')
			//Data fields are merged so we can use them directly to take title and metaDescription for each route from them
			.mergeMap((route) => route.data)
			//Real action starts there
			.subscribe((event) => {
				//Changing title
				this.titleService.setTitle(event['title']);

				//Changing meta with name="description"
				var tag = {
					name: 'description',
					content: event['metaDescription'],
				};
				let attributeSelector: string = 'name="description"';
				this.metaService.removeTag(attributeSelector);
				this.metaService.addTag(tag, false);

				//Changing meta with name="keyword"
				var meta = { name: 'keyword', content: event['metaKeyword'] };
				let attributeSelector1: string = 'name="keywork"';
				this.metaService.removeTag(attributeSelector1);
				this.metaService.addTag(meta, false);
			});
	}
}
